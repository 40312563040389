

// TODO: Replace the following with your app's Firebase project configuration
export   const firebaseConfig = {
    apiKey: "AIzaSyCmD1BZXJpeNFFDf-j7JsJJpsDe3IJfPWE",
    authDomain: "xvba-repository.firebaseapp.com",
    databaseURL: "https://xvba-repository.firebaseio.com",
    projectId: "xvba-repository",
    storageBucket: "xvba-repository.appspot.com",
    messagingSenderId: "99552018593",
    appId: "1:99552018593:web:af90340f6c866966c030e0",
    measurementId: "G-P7P2R7RE3W"
  };

